import React, { useEffect } from "react";
import Logo from "./Company'sLogo";
import { Link } from "react-router-dom";
import Login from "./Login";

const Header = (props)=>{

    const isChild = (element,parent)=>{
        if(element === document.body){
            return false;
        }
        if(element === parent){
            return true
        }
        return isChild(element.parentElement,parent);
    }

    const showLoginForm = (e)=>{
        const login = document.getElementById("login");
        const background = document.getElementById("bg")
        login.style.display = "flex";
        background.style.display = "block";
        const closeLoginForm = (e)=>{
            if(!isChild(e.target,login)){
                login.style.display = "none";
                background.style.display = "none";
                document.removeEventListener("click",closeLoginForm)
            }
        }
        setTimeout(()=>{
            document.addEventListener("click",closeLoginForm);
        },10)
    }

    useEffect(()=>{
        const login = props.login;
        if(login === 1){
            showLoginForm();
            props.setLogin(0);
        }else{
            return;
        }
    },[props.login])

    const showInfo = (e)=>{
        let moreInfo = document.getElementById("more-info");
        if(moreInfo.style.display === "block"){
            moreInfo.style.display = "none";
            return;
        }
        moreInfo.style.display = "block";
        const event = (e)=>{
            if(e.target === moreInfo || e.target.parentElement === moreInfo){
                // console.log(e.target)
            }
            else{
                // console.log(e.target)
                moreInfo.style.display = "none";
                document.removeEventListener("click",event);
            }
        }
        setTimeout(()=>{
            document.addEventListener("click",event);
        },10)
    }

    const logout = (e)=>{
        localStorage.removeItem("userToken");
        localStorage.removeItem("username");
        localStorage.setItem("login", false);
        window.location.reload();
    }

    useEffect(()=>{
        const moreInfoBtn = document.getElementById("more-info-button");
        const moreInfoDiv = document.getElementById("more-info");
        const bottom = moreInfoBtn.getBoundingClientRect().bottom;
        const left = moreInfoBtn.getBoundingClientRect().left;
        moreInfoDiv.style.top = `${bottom-30}px`;
        moreInfoDiv.style.left = `${left-2}px`;
        if(localStorage.getItem("login") === "true"){
            document.getElementById("adminName").innerHTML = localStorage.getItem("username");
            document.getElementById("log").innerHTML = "تغيير الحساب";
            document.getElementById("logout").style.display = "block";
        }
        else{
            document.getElementById("adminName").innerHTML = "تسجيل الدخول";
            document.getElementById("log").innerHTML = "تسجيل الدخول";
            document.getElementById("logout").style.display = "none";
        }
    },[])

    return (
        <>
        <div className="main-header">
            <div className="logo">
                <Logo/>
            </div>
            <div className="Account" onClick={showInfo} id="more-info-button">
                <div className="admin-name">
                    <span id="adminName" className="admin-name-span">عبد الله</span>    
                    <span className="admin-span">أدمن</span>
                </div>
            </div>
        </div>
        <div id="more-info" className="more-info">
            <div className="up-arrow"></div>
            <div className="logger">
                <button onClick={showLoginForm} style={{textDecoration:"none"}} id="log" className="log">تسجيل الدخول</button>
                <button id="logout" onClick={logout} className="log">تسجيل الخروج</button>
            </div>
        </div>
        <Login/>
        <div id="bg" className="bg"></div>
        <div className="" id="loading" style={{display:"none"}}>
            {/* جاري التحميل */}
        </div>
        </>
    )
}

export default Header;