import React, { useEffect } from "react";
import { useState } from "react";
import { postData } from "./imporatnt_functions";

const Elements = (props)=>{

    let [type,setType] = useState("total");
    let [numberOfOrders,setNumberOfOrders] = useState({})
    // let [numberOfReady,setNumberOfReady] = useState()
    // let [numberOfGettingPrepared,setNumberOfGettingPrepared] = useState()
    // let [numberOfDelivered,setNumberOfDelivered] = useState()
    // let [numberOfwaiting,setNumberOfwaiting] = useState()

    const chooseType = (e)=>{
        // console.log(e.target)
        if(e.target.tagName === "DIV"){
            e.target.classList.toggle("selected");
            document.getElementById(type).classList.toggle("selected");
            setType(e.target.id);
            props.setOrdersType(e.target.id);
            // console.log(e.target)
        }else{
            const selectedDiv = e.target.parentElement;
            document.getElementById(type).classList.toggle("selected");
            selectedDiv.classList.toggle("selected");
            setType(selectedDiv.id);
            props.setOrdersType(selectedDiv.id);
            // console.log(selectedDiv);
        }
    }

    // useEffect(()=>{
    //     if(localStorage.getItem("login") === "false") return;
    //     let data = new FormData();
    //     postData("https://rassi.altarek.tech/api/admin/getMyOrders",data,localStorage.getItem("userToken")).then((response)=>{
    //             console.log("f")
    //             // setNumberOfOrders(response.data.total_numbers)
    //             // props.setNumbers(
    //             //     {
    //             //         "":response.data.total_numbers["total"],
    //             //         "1":response.data.total_numbers["waiting"],
    //             //         "2":response.data.total_numbers["preparing"],
    //             //         "3":response.data.total_numbers["ready"],
    //             //         "4":response.data.total_numbers["delivered"],
    //             //     });
    //         })
    // },[])
    return (
        <>
            <div onClick={chooseType} id="total" className="selected">
            الكل
                {/* <button className="all-type">الكل</button> */}
                <span className="number-of-orders">{props.numberOfOrders["total"]}</span>
                {/* <span className="number-of-orders">{numberOfOrders}</span> */}
            </div>
            <div onClick={chooseType} id="preparing">
            جارى التجهيز
                {/* <button className="getting-prepared-type">جارى التجهيز</button> */}
                <span className="number-of-orders">{props.numberOfOrders["preparing"]}</span>
                {/* <span className="number-of-orders">{numberOfGettingPrepared}</span> */}
            </div>
            <div onClick={chooseType} id="ready">
            جاهز
                {/* <button className="ready-type">جاهز</button> */}
                <span className="number-of-orders">{props.numberOfOrders["ready"]}</span>
            </div>
            <div onClick={chooseType} id="delivered">
            تم التسليم
                {/* <button className="delivered-type">تم التسليم</button> */}
                <span className="number-of-orders">{props.numberOfOrders["delivered"]}</span>
            </div>
            <div onClick={chooseType} id="waiting">
            في الانتظار
                {/* <button className="waiting-type">في الانتظار</button> */}
                <span className="number-of-orders">{props.numberOfOrders["waiting"]}</span>
            </div>
    </>
    )
    
}

export default Elements;