import React, { useEffect, useState } from "react";
import Logo from "./Company'sLogo";
import { Link, useNavigate } from "react-router-dom";
import Login from "./Login";
import Elements from "./Elements";
import Stock from "./Stock";
import OrderWindow from "./OrderWindow";

const Header2 = (props)=>{

    let [type,setOrdersType] = useState("total");
    let [search,setSearch] = useState("`");
    let [orderToBeShown,setOrderToBeShown] = useState("");
    let [numberOfOrders,setNumberOfOrders] = useState({})

    let [pcList,setPcList] = useState("");
    let [phoneList,setPhoneList] = useState("");

    const navigate = useNavigate();

    // const isChild = (element,parent)=>{
    //     if(element === document.body){
    //         return false;
    //     }
    //     if(element === parent){
    //         return true
    //     }
    //     return isChild(element.parentElement,parent);
    // }

    // const showLoginForm = (e)=>{
    //     const login = document.getElementById("login");
    //     const background = document.getElementById("bg")
    //     login.style.display = "flex";
    //     background.style.display = "block";
    //     const closeLoginForm = (e)=>{
    //         if(!isChild(e.target,login)){
    //             login.style.display = "none";
    //             background.style.display = "none";
    //             document.removeEventListener("click",closeLoginForm)
    //         }
    //     }
    //     setTimeout(()=>{
    //         document.addEventListener("click",closeLoginForm);
    //     },10)
    // }

    const hideTypeList = (e)=>{
        document.getElementById("bg").style.display = "none";
        document.getElementById("type-list-phone").style.display = "none";
        document.removeEventListener("click",hideTypeList);
    }

    const showTypeList = (e)=>{
        document.getElementById("bg").style.display = "block";
        document.getElementById("type-list-phone").style.display = "block";

        setTimeout(()=>{
            document.addEventListener("click",hideTypeList);
        },10)
    }

    const showInfo = (e)=>{
        let moreInfo = document.getElementById("more-info");
        if(moreInfo.style.display === "block"){
            moreInfo.style.display = "none";
            return;
        }
        moreInfo.style.display = "block";
        const event = (e)=>{
            if(e.target === moreInfo || e.target.parentElement === moreInfo){
            }
            else{
                moreInfo.style.display = "none";
                document.removeEventListener("click",event);
            }
        }
        setTimeout(()=>{
            document.addEventListener("click",event);
        },10)
    }

    const logout = (e)=>{
        localStorage.removeItem("userToken");
        localStorage.removeItem("username");
        localStorage.setItem("login", false);
        window.location.reload();
    }

    useEffect(()=>{
        const moreInfoBtn = document.getElementById("more-info-button");
        const moreInfoDiv = document.getElementById("more-info");
        const bottom = moreInfoBtn.getBoundingClientRect().bottom;
        const left = moreInfoBtn.getBoundingClientRect().left;
        moreInfoDiv.style.top = `${bottom-30}px`;
        moreInfoDiv.style.left = `${left-2}px`;
        if(localStorage.getItem("login") === "true"){
            document.getElementById("adminName").innerHTML = localStorage.getItem("username");
            document.getElementById("log").innerHTML = "تغيير الحساب";
            document.getElementById("logout").style.display = "block";
        }
        else{
            document.getElementById("adminName").innerHTML = "تسجيل الدخول";
            document.getElementById("log").innerHTML = "تسجيل الدخول";
            document.getElementById("logout").style.display = "none";
        }
    },[])

    useEffect(()=>{
        const typeList = document.getElementById("type-list");
        const secondHeader = document.getElementById("second-header");
        secondHeader.style.width = `${secondHeader.width}`;
    })

    useEffect(()=>{
        setTimeout(()=>{
            const deviceWidth = document.documentElement.clientWidth;
            if(deviceWidth >= 1050){
                setPcList(<Elements setOrdersType={setOrdersType} numberOfOrders={numberOfOrders}/>);
                
            }else if(deviceWidth < 1050){
                setPhoneList(<Elements setOrdersType={setOrdersType} numberOfOrders={numberOfOrders}/>)
            }
        },100)
    },[document.documentElement.clientWidth,numberOfOrders]);

    return (
        <>
        <div id="bg" className="bg"></div>
        <div className="" id="loading" style={{display:"none"}}>
        </div>
        <div className="page-header">
            <div className="main-header">
                <div className="logo">
                    <Logo/>
                </div>
                <div className="type-list" id="type-list">
                    {pcList}
                </div>
                <div className="Account" onClick={showInfo} id="more-info-button">
                    <div className="admin-name">
                        <span id="adminName" className="admin-name-span">عبد الله</span>    
                        <span className="admin-span">أدمن</span>
                    </div>
                </div>
            </div>
            <div className="second-header" id="second-header">
                <form className="search-orders" onSubmit={(e)=>{
                        const data = new FormData(e.target);
                        // console.log(Array.from(data)[0][1])
                        // console.log(Array.from(data));
                        e.preventDefault();
                        setSearch(Array.from(data)[0][1]);
                    }}>
                    <div className="filter-btn">
                        فلترة الطلبات
                    </div>
                    <input className="search-orders-input" id="search-orders-input" type="text" name="text" placeholder="ابحث برقم الطلب او اسم العميل"/>
                    <button className="search-orders-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.669" height="28.669" viewBox="0 0 28.669 28.669">
                            <path id="Path_9200" data-name="Path 9200" d="M30.088,27.646,23.72,21.293a12.107,12.107,0,1,0-2.427,2.427l6.354,6.368a1.728,1.728,0,0,0,2.441,0h0a1.728,1.728,0,0,0,0-2.441ZM14.083,22.692a8.567,8.567,0,1,1,8.567-8.567,8.567,8.567,0,0,1-8.567,8.567Z" transform="translate(-1.924 -1.924)" fill="#707070"/>
                        </svg>
                    </button>
                </form>
                <Link className="create-new-order" to={"/createneworder"} style={{textDecoration:"none"}}>
                    <button className="new-order">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.408" height="19.408" viewBox="0 0 19.408 19.408">
                            <path id="Path_17022" data-name="Path 17022" d="M359.821,151.117a1.123,1.123,0,0,1,1.087,1.087,1.068,1.068,0,0,1-.328.783,1.029,1.029,0,0,1-.758.3h-7.53v7.53a1.068,1.068,0,0,1-.328.783,1.023,1.023,0,0,1-.758.3,1.041,1.041,0,0,1-1.087-1.087v-7.53h-7.53A1.041,1.041,0,0,1,341.5,152.2a1.023,1.023,0,0,1,.3-.758,1.068,1.068,0,0,1,.783-.328h7.53v-7.53a1.029,1.029,0,0,1,.3-.759,1.068,1.068,0,0,1,.783-.328,1.123,1.123,0,0,1,1.087,1.087v7.53Z" transform="translate(-341.5 -142.5)" fill="#fff"/>
                        </svg>
                        إنشاء طلب جديد
                    </button>
                </Link>
            </div>
            <div className="orders-phone">
                <input className="search-orders-input" type="text" placeholder="ابحث برقم الطلب او اسم العميل" onKeyDown={(e)=>{
                    if(e.keyCode === 13)
                        setSearch(e.target.value);
                }}/>
                <button className="show-orders-list" onClick={showTypeList}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="19.5" viewBox="0 0 21.5 19.5">
                        <g id="Group_13503" data-name="Group 13503" transform="translate(0.75 0.75)">
                            <path id="Path_6448" data-name="Path 6448" d="M22,6.5H16" transform="translate(-2 -3)" fill="none" stroke="#f2a057" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_6449" data-name="Path 6449" d="M6,6.5H2" transform="translate(-2 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_6450" data-name="Path 6450" d="M10,10A3.5,3.5,0,1,0,6.5,6.5,3.5,3.5,0,0,0,10,10Z" transform="translate(-2 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_6451" data-name="Path 6451" d="M8,17.5H2" transform="translate(-2 -3)" fill="none" stroke="#f2a057" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_6452" data-name="Path 6452" d="M22,17.5H18" transform="translate(-2 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_6453" data-name="Path 6453" d="M14,21a3.5,3.5,0,1,0-3.5-3.5A3.5,3.5,0,0,0,14,21Z" transform="translate(-2 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
                        </g>
                    </svg>
                </button>
                    <button onClick={(e)=>{navigate("/createneworder")}} className="create-new-order-phone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.408" height="19.408" viewBox="0 0 19.408 19.408">
                            <path id="Path_17022" data-name="Path 17022" d="M359.821,151.117a1.123,1.123,0,0,1,1.087,1.087,1.068,1.068,0,0,1-.328.783,1.029,1.029,0,0,1-.758.3h-7.53v7.53a1.068,1.068,0,0,1-.328.783,1.023,1.023,0,0,1-.758.3,1.041,1.041,0,0,1-1.087-1.087v-7.53h-7.53A1.041,1.041,0,0,1,341.5,152.2a1.023,1.023,0,0,1,.3-.758,1.068,1.068,0,0,1,.783-.328h7.53v-7.53a1.029,1.029,0,0,1,.3-.759,1.068,1.068,0,0,1,.783-.328,1.123,1.123,0,0,1,1.087,1.087v7.53Z" transform="translate(-341.5 -142.5)" fill="#fff"/>
                        </svg>
                        إنشاء طلب 
                    </button>
                <div className="type-list-phone" id="type-list-phone">
                    {phoneList}
                </div>
            </div>
            <Stock type={type} searchFor={search} setOrder={setOrderToBeShown} showLoginForm={props.showLoginForm} setNumberOfOrders={setNumberOfOrders}/>
            <OrderWindow order={orderToBeShown}/>
        </div>
        <div id="more-info" className="more-info">
            <div className="up-arrow"></div>
            <div className="logger">
                <button onClick={props.showLoginForm} style={{textDecoration:"none"}} id="log" className="log">تسجيل الدخول</button>
                <button id="logout" onClick={logout} className="log">تسجيل الخروج</button>
            </div>
        </div>
        <Login/>
        </>
    )
}

export default Header2;