import React, { useEffect, useState } from "react";

const OrderWindow = (props)=>{
    
    const status = {
        1:"جارى التجهيز",
        2:"جاهز",
        3:"في الانتظار",
        4:"تم التسليم"
    }

    let [order,setOrder] = useState("");

    useEffect(()=>{
        setOrder(props.order)
        // console.log(props.order)
    },[props.order])

    return(
        <div className="order-window" id="order-window">
            <h1>الطلب رقم <span style={{color:"#000"}}>{order.order_number}</span></h1>
            <div className="info">
                <div className="text-info">   
                    <div>اسم العميل&nbsp;:&nbsp;<span>{order.client_name}</span></div>
                    <div>المستودع&nbsp;:&nbsp;<span>{order.warehouse_name}</span></div>
                    <div>الحالة&nbsp;:&nbsp;<span>{status[order.status]}</span></div>
                    <div>تاريخ الطلب&nbsp;:&nbsp;<span>{order.order_date}</span></div>
                    <div>موعد التسليم&nbsp;:&nbsp;<span>{order.due_date}</span></div>
                </div>
                <div className="image">
                    {/* <img src={"https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg"} alt="image" /> */}
                    {console.log(order.image)}
                    <img src={order.image} alt="image" />
                </div>
            </div>
        </div>
    )
}

export default OrderWindow;