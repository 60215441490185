import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import OrderWindow from "./OrderWindow";
import { isChild } from "./imporatnt_functions";

const status = {
    2:"جارى التجهيز",
    3:"جاهز",
    1:"في الانتظار",
    4:"تم التسليم"
}

const statusColor = {
    2:"#f6c1bc",
    3:"#BBDFE2",
    1:"#FFF3E8",
    4:"#DBF4DC",
}

const fontColor = {
    2:"#E34231",
    3:"#1A7279",
    1:"#BC5F09",
    4:"#12A525"
}

const Order = (props)=>{
    let [order,setOrder] = useState(props.order);
    let [updateDate,setUpdateDate] = useState();

    let navigate = useNavigate()
    const addStocks = ()=>{
        // document.getElementById(`stock-${order.num}`).add(new Option("مستودع","stt"));
    }

    const hideListEvent = (e)=>{
        // console.log(e.target.id)
        if(e.target.id === "modify-order-btn"){
            document.removeEventListener("click",hideListEvent);
            navigate("../modifyorder",{state:order});
            return;
        }else{
            document.getElementById(`procedureList${order.order_number}`).style.display = "none";
            document.removeEventListener("click",hideListEvent);
        }
    }

    function showProceduresList(e){
        if(document.getElementById(`procedureList${order.order_number}`).style.display === "block"){
            document.getElementById(`procedureList${order.order_number}`).style.display = "none";
            return;
        }
        const list = document.getElementById(`procedureList${order.order_number}`);
        list.style.display = "block";
        setTimeout(()=>{
            document.addEventListener("click",hideListEvent)
        },10)
    }

    const showProceduresListPhone = (e)=>{

        function getOrder(ele){
            if(ele.classList.contains("order")){
                return ele;
            }
            // console.log(ele)
            return getOrder(ele.parentElement);
        }
        if(getOrder(e.target).classList.contains("clicked")){
            getOrder(e.target).style.left = "0px";
            return;
        }
        
        const modifyBtn = document.getElementById("modify-order-phone");
        const orderDiv = getOrder(e.target);
        // console.log(orderDiv)
        orderDiv.style.position = "relative";
        orderDiv.style.left = "30vw";
        orderDiv.classList.toggle("clicked");
        const hideProceduresListPhone = (e)=>{
            if(e.target.id === "modify-order-phone"){
                navigate("../modifyorder",{state:order});
            }else{
                // console.log(orderDiv)
                orderDiv.style.position = "relative";
                orderDiv.style.left = "0px";   
                orderDiv.classList.toggle("clicked");
                document.removeEventListener("click",hideProceduresListPhone);
            }
        }

        setTimeout(()=>{
            document.addEventListener("click",hideProceduresListPhone);
        },10)

    }

    const isChildById = (ele,id,targetElement) => {
        // console.log(ele)
        if(ele.id === id || ele.id === "procedure-btn-phone") return true;
        else if(ele.id === targetElement) return false
        return isChildById(ele.parentElement,id,targetElement);
    }

    const showOrderWindow = (e)=>{
        // console.log(e.target)
        const unwantedParent = document.getElementById("procedure");
        if(isChildById(e.target,"procedure","order")) return;
        props.showOrder(order);
        const orderWindow = document.getElementById("order-window");
        const bg = document.getElementById("bg");
        orderWindow.style.display = "block";
        bg.style.display = "block";

        const hideOrderWindow = (e)=>{
            if(isChild(e.target,orderWindow)) return
            orderWindow.style.display = "none";
            bg.style.display = "none";
            document.removeEventListener("click",hideOrderWindow)
        }

        setTimeout(()=>{
            document.addEventListener("click",hideOrderWindow);
        },10)
    }

    useEffect(()=>{
        addStocks()
        const updateDate = new Date(order.updated_at);
        const year = updateDate.getFullYear();
        const month = updateDate.getMonth()+1;
        const day = updateDate.getDate();
        setUpdateDate(`${day}-${month}-${year}`);
    },[order])

    return(
        <>
            <div className="order" id="order" onClick={showOrderWindow}>
                {/* <div className="hide-in-phone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.067" height="17.067" viewBox="0 0 17.067 17.067">
                        <g id="Rectangle_5875" data-name="Rectangle 5875" fill="#fff" stroke="#707070" stroke-width="1">
                            <rect width="17.067" height="17.067" rx="5" stroke="none"/>
                            <rect x="0.5" y="0.5" width="16.067" height="16.067" rx="4.5" fill="none"/>
                        </g>
                    </svg>
                </div> */}
                <div className="order-number main-div">{order.order_number}</div>
                <div className="order-name hide-in-phone main-div">{order.client_name}</div>
                <div className="order-date main-div">{order.order_date}</div>
                <div id="updateDate" className="update-date hide-in-phone main-div">{updateDate}</div>
                <div className="order-initiator hide-in-phone main-div">{order.admin_name}</div>
                {/* <select id={`stock-${order.num}`} name="stocks" className="stock-btn" value={order.stock}>{order.stock}</select> */}
                <div className="stock-btn main-div">
                    {order.warehouse_name}
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.942" height="8.072" viewBox="0 0 13.942 8.072">
                        <path id="caret-down-svgrepo-com" d="M7.954,9.533a1.1,1.1,0,0,0,0,1.557l5.87,5.87a1.1,1.1,0,0,0,1.557,0l5.87-5.87a1.1,1.1,0,1,0-1.557-1.557L14.6,14.625,9.511,9.533A1.1,1.1,0,0,0,7.954,9.533Z" transform="translate(-7.632 -9.211)" fill="#1a7279" fill-rule="evenodd"/>
                    </svg>
                </div>
                <div className="order-status main-div" style={{backgroundColor:statusColor[order.status],color:fontColor[order.status]}}>{status[order.status]}</div>
                {/* <div className="quantity">{order.quantity}</div> */}
                <div onClick={showProceduresList} id="procedure" className="procedure hide-in-phone" style={{cursor:"pointer"}}>
                    <svg className="hide-in-phone" xmlns="http://www.w3.org/2000/svg" width="13.942" height="8.072" viewBox="0 0 13.942 8.072">
                        <path id="caret-down-svgrepo-com" d="M7.954,9.533a1.1,1.1,0,0,0,0,1.557l5.87,5.87a1.1,1.1,0,0,0,1.557,0l5.87-5.87a1.1,1.1,0,1,0-1.557-1.557L14.6,14.625,9.511,9.533A1.1,1.1,0,0,0,7.954,9.533Z" transform="translate(-7.632 -9.211)" fill="#1a7279" fill-rule="evenodd"/>
                    </svg>
                    <div id={`procedureList${order.order_number}`} className="procedures-list">
                        <div className="modify-order-btn" id="modify-order-btn">تعديل</div>
                    </div>
                </div>
                <button className="procedure-btn-phone" id="procedure-btn-phone" onClick={showProceduresListPhone}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="3.589" height="14.355" viewBox="0 0 3.589 14.355">
                        <path id="Path_32463" data-name="Path 32463" d="M81.794,37.383a1.794,1.794,0,1,0,1.794,1.794A1.8,1.8,0,0,0,81.794,37.383Zm0-5.383a1.794,1.794,0,1,0,1.794,1.794A1.8,1.8,0,0,0,81.794,32Zm0,10.767a1.794,1.794,0,1,0,1.794,1.794A1.8,1.8,0,0,0,81.794,42.767Z" transform="translate(-80 -32)" fill="#72ba8c"/>
                    </svg>
                </button>
                <div id="modify-order-phone" className="modify-order-phone">تعديل</div>
            </div>
        </>
    )
}

export default Order;