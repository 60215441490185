const url = "https://rassi.altarek.tech/api/";

const login = async (url,data)=>{
    try{
        let response = await fetch(url,{
            mode:"cors",
            method:"POST",
            mode:"cors",
            // credentials:"include",
            body:data
        });
        response = await response.json();
        return response;
    }catch(error){
        return error;
    }
}

const postData = async (url,data,userToken)=>{
    try{
        let response = await fetch(url,{
            method:"POST",
            mode:"cors",
            headers:{
                "Authorization":`Bearer ${userToken}`,
            },
            body:data
        });
        console.log(response.status)
        if(response.status === 500){
            return response
        }
        response = await response.json();
        return response;
    }catch(error){
        return error;
    }
}

const post = async (url,data)=>{
    try{
        let response = await fetch(url,{
            mode:"cors",
            method:"POST",
            mode:"cors",
            body:data
        });
        console.log(response)
        response = await response.json();
        return response;
    }catch(error){
        return error;
    }
}

const getWarehouses = async (userToken)=>{
    try{
        let response = await fetch("https://rassi.altarek.tech/api/superAdmin/getWarehouses",{
            headers:{
                "Authorization":`Bearer ${userToken}`,
            },
        });
        response = await response.json();
        return response;    
    }catch(error){
        return error;
    }
}

const isChild = (element,parent)=>{
    if(element === document.body){
        return false;
    }
    if(element === parent){
        return true
    }
    return isChild(element.parentElement,parent);
}

export {
    postData,
    login,
    post,
    getWarehouses,
    isChild,
    url
}