import './App.css';
import RepositroyPage from './RepositoryPage';
import Header from './Header';
import Orders from './Orders';
import Login from './Login';
import { Link } from 'react-router-dom';
import {BrowserRouter,Route,Routes} from "react-router-dom";
import CreateNewOrder from './CreateNewOrder';
import ModifyOrder from './ModifyOrder';
import Header2 from './Header2';

function App() {

  const isChild = (element,parent)=>{
      if(element === document.body){
          return false;
      }
      if(element === parent){
          return true
      }
      return isChild(element.parentElement,parent);
}

  const showLoginForm = (e)=>{
      const login = document.getElementById("login");
      const background = document.getElementById("bg")
      login.style.display = "flex";
      background.style.display = "block";
      const closeLoginForm = (e)=>{
          if(!isChild(e.target,login)){
              login.style.display = "none";
              background.style.display = "none";
              document.removeEventListener("click",closeLoginForm)
          }
      }
      setTimeout(()=>{
          document.addEventListener("click",closeLoginForm);
      },10)
}


  return (
    <>
    <BrowserRouter>
      <Routes>
        {/* <Route path="" element={<RepositroyPage/>}></Route> */}
        <Route path="" element={<Header2 showLoginForm={showLoginForm}/>}></Route>
        <Route path="/createneworder" element={<CreateNewOrder showLoginForm={showLoginForm}/>}></Route>
        <Route path="/modifyorder" element={<ModifyOrder/>}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
