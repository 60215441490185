import React, { useEffect, useState } from "react";
import Header from "./Header";
import Header2 from "./Header2";
import { postData,getWarehouses,url } from "./imporatnt_functions";
import { useNavigate,useLocation } from "react-router-dom";

const CreateNewOrder = (props)=>{

    let [image,setImage] = useState('');

    const navigate = useNavigate();

    function addPicture(e){
        // console.log(e.target.files[0])
        if(e.target.files[0]){
            let image = URL.createObjectURL(e.target.files[0]);
            document.getElementById("warehouse_image").src = image;
            // console.log(image)
            setImage(e.target.files[0]);
        }
    }

    const createOrderSubmit = (e)=>{
        e.preventDefault();
        const form = document.getElementById("new-order");
        // console.log(form)
        let data = new FormData(form)
        // console.log(Array.from(data))
        const dataArray = Array.from(data);
        let f=0;
        for(let field of dataArray){
            if(field[1] === ""){
                document.getElementById(field[0]).style.borderColor = "red";

                let counter = 0;
                const shaking = setInterval(()=>{
                    if(counter === 1){
                        clearInterval(shaking)
                    }
                    counter ++;
                    document.getElementById(field[0]).style.position = "relative"
                    // document.getElementById(field[0]).style.left = "0px";
                    document.getElementById(field[0]).style.transform = "translateX(5px)";
                    setTimeout(()=>{
                        document.getElementById(field[0]).style.transform = "translateX(-5px)";
                    },50);
                    setTimeout(()=>{
                        document.getElementById(field[0]).style.transform = "translateX(0px)";
                    },70);
                },100)

                f=1;
            }else 
                document.getElementById(field[0]).style.borderColor = "#1A7279";
        }
        if(f) return
        const bg = document.getElementById("bg");
        const loadingDiv = document.getElementById("loading");
        bg.style.display = "block";
        loadingDiv.style.display = "block";
        loadingDiv.classList.toggle("loader");
        // console.log("create")
        postData(`${url}admin/createAdminOrders`,data,localStorage.getItem("userToken")).then(response=>{
            loadingDiv.classList.toggle("loader");
            loadingDiv.classList.toggle("loading");
            if(response.status === "false"){
                loadingDiv.innerText = "حدث خطأ.";
            }
            else{
                loadingDiv.innerText = "تم";
            } 
            setTimeout(()=>{
                bg.style.display = "none";
                loadingDiv.style.display = "none";
                loadingDiv.classList.toggle("loading");
                loadingDiv.innerText = "";
                if(response.status !== "false") navigate(-1); 
            },1000);
        }).catch(err=>{
            loadingDiv.innerText = "حدث خطأ.";
            setTimeout(()=>{
                bg.style.display = "none";
                loadingDiv.style.display = "none";
                loadingDiv.classList.toggle("loading");
                loadingDiv.innerText = "";
            },1000);
            return err
        })
    }

    const setWarehouses = async ()=>{
        const warehouses = document.getElementById("warehouse_id")
        getWarehouses(localStorage.getItem("userToken")).then(response=>{
            // console.log(response)
            response.warehouses.forEach(warehouse=>{
                warehouses.add(new Option(warehouse.name,warehouse.id));
            })
        }).catch(err=>(err))
    }


    useEffect(()=>{
        setWarehouses();
    },[])

    useEffect(()=>{
        if(localStorage.getItem("login") === "false"){
            const askToLogin = document.getElementById("ask-to-login-create-new-order");
            const container = document.getElementById("new-order");
            askToLogin.style.display = "flex";
            askToLogin.style.flexDirection = "row";
            askToLogin.style.justifyContent = "center";
            askToLogin.style.alignItems = "center";
            askToLogin.style.width = "100%";
            askToLogin.style.height = "400px";

            container.style.display = "none";
            
        }
    })

    return(
        <>
        <div className="new-order-page">
            <Header/>
            <div className="create-new-order-main-container">
                <div className="create-new-order-header">
                    <header>إنشاء طلب جديد</header>
                    <button className="return-to-main" id="return-create-order" onClick={e=>navigate(-1)}>
                        الرجوع
                        &#8678;
                    </button>
                </div>
                <form id="new-order" className="order-options">
                    <div className="main-form">
                        <div className="right-side-container">
                            <label htmlFor="warehouse_id">المستودع</label>
                            <select name="warehouse_id" id="warehouse_id">
                            </select>
                            <label htmlFor="order_number">رقم الطلب</label>
                            <input type="number" name="order_number" id="order_number" min={0}/>
                            <label htmlFor="client_name">اسم العميل</label>
                            <input type="text" name="client_name" id="client_name"/>
                            <label htmlFor="order_date">تاريخ الطلب</label>
                            <input type="date" name="order_date" id="order_date"/>
                            <label htmlFor="due_date">موعد التسليم</label>
                            <input type="date" name="due_date" id="due_date"/>
                        </div>
                        <div className="image-side-container">
                            <img id="warehouse_image" src="./image.jpg" className="warehouse-image" alt="صورة المستودع"/>
                            <label htmlFor="image">
                                <svg style={{color:"#fff"}} fill="#fff" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200Zm480-480v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM240-280h480L570-480 450-320l-90-120-120 160Zm-40-480v560-560Z"/></svg>
                                إضافة صورة
                            </label>
                            <input type="file" name="image" accept="image/png,image/jpg,image/jpeg" id="image" onChange={addPicture}/>
                        </div>
                    </div>
                    <button id="newOrderSubmitButton" onClick={createOrderSubmit}>
                        <svg className="add-svg" xmlns="http://www.w3.org/2000/svg" width="16.727" height="16.727" viewBox="0 0 16.727 16.727">
                            <g id="Group_13503" data-name="Group 13503" transform="translate(-1126.637 -2491.785)">
                                <path id="Vector" d="M2.98,0a.812.812,0,0,1,.733.872V14.855a.812.812,0,0,1-.733.872H.733A.812.812,0,0,1,0,14.855V.872A.812.812,0,0,1,.733,0Z" transform="translate(1142.864 2498.292) rotate(90)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                                <path id="Vector-2" data-name="Vector" d="M3.24,0a.837.837,0,0,1,.8.872V14.855a.837.837,0,0,1-.8.872H.8a.837.837,0,0,1-.8-.872V.872A.837.837,0,0,1,.8,0Z" transform="translate(1132.982 2492.285)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                            </g>
                        </svg>
                        إضافة طلب جديد
                    </button>
                </form>
                <div className="ask-to-login" id="ask-to-login-create-new-order" style={{display:"none"}}>
                    قم <button className="ask-to-login-button" onClick={e=>props.showLoginForm(e)}>بتسجيل الدخول</button> لإنشاء طلب جديد
                </div>
            </div>
        </div>
        </>
    )
}

export default CreateNewOrder;