import React from "react";
import { login,url } from "./imporatnt_functions";

const Login = ()=>{

    const showPassword = (e)=>{
        e.preventDefault();
        const pswrd = document.getElementById("password");
        if(pswrd.type === "password") pswrd.type = "text";
        else if(pswrd.type === "text") pswrd.type = "password";
    }

    const closeLogin = (e)=>{
        e.preventDefault()
        document.getElementById("login").style.display = "none";
        document.getElementById("bg").style.display = "none";
        window.location.reload();
    }

    const submit = async (e)=>{
        e.preventDefault();
        let data = new FormData(e.target);
        // data = Object.fromEntries(data.entries());
        // console.log(Array.from(data));
        const notify = document.getElementById("notification");
        login(`${url}login`,data).then(res=>{
            // console.log(res.status)
            if(res.status === "false"){
                // console.log("Enter the valid data");
                notify.innerHTML = "Enter valid data";
                // console.log(res.error)
                return;
            }else{
                // console.log(res);
                // console.log(res.user.token);
                localStorage.setItem("userToken",res.user.token);
                localStorage.setItem("username",res.user.name);
                localStorage.setItem("login",true);
                notify.innerHTML = "successfully logged in";
                window.location.reload();
            }
        }).catch(err=>{
            // console.log(err);
            notify.innerHTML = "Invalid email or password";
        })
    }

    return(
        <>
            <div id="login" className="login">
                <form className="login-form" id="login-form" onSubmit={submit}>
                <div className="login-header">
                <header>تسجيل الدخول</header>
                <div className="exit-login" onClick={closeLogin}>الرجوع</div>
                </div>
                    {/* <div className="login-by-phonenumber">
                        <button className="code">
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.616" height="7.808" viewBox="0 0 13.616 7.808">
                                <path id="_05d672bf2ec15f434a544f7a2214100f" data-name="05d672bf2ec15f434a544f7a2214100f" d="M19.287,11.5l-5.394,5.394L8.5,11.5" transform="translate(-7.086 -10.086)" fill="none" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                            </svg>
                            966
                        </button>
                        <input id="phone-number" className="phone-number" type="number" placeholder="5xxxxxxx"/>
                    </div> */}
                    <input name="type" type="text" value={"email"} style={{display:"none"}}/>
                    {/* <div className="login-by-mail"> */}
                        <input name="email" id="mail" className="mail" type="mail" placeholder="abc@mail.com"/>
                    {/* </div> */}
                    <div className="password">
                        <input name="password" className="pswrd" id="password" type="password" placeholder="كلمة المرور***" />
                        <div className="see-password" onClick={showPassword}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.21" height="19.696" viewBox="0 0 26.21 19.696">
                                <path id="Path_32508" data-name="Path 32508" d="M61.605,208.2a11.9,11.9,0,0,1-6.976-2.359,19.269,19.269,0,0,1-4.411-4.412,22.362,22.362,0,0,1-1.641-2.615,1.751,1.751,0,0,1,0-1.026l.1-.1a19.719,19.719,0,0,1,1.949-2.924,17.736,17.736,0,0,1,4.36-4.155,11.668,11.668,0,0,1,6.617-2.1,11.9,11.9,0,0,1,6.976,2.359,19.268,19.268,0,0,1,4.411,4.412,22.368,22.368,0,0,1,1.641,2.615,1.749,1.749,0,0,1,0,1.026l-.1.1a19.719,19.719,0,0,1-1.949,2.924,17.735,17.735,0,0,1-4.36,4.155A11.668,11.668,0,0,1,61.605,208.2Zm-10.823-9.848a17.127,17.127,0,0,0,3.539,4.462,10.736,10.736,0,0,0,7.284,3.18,10.558,10.558,0,0,0,7.232-3.18,21.3,21.3,0,0,0,3.59-4.462,17.127,17.127,0,0,0-3.539-4.462,10.736,10.736,0,0,0-7.283-3.18,10.558,10.558,0,0,0-7.232,3.18A21.3,21.3,0,0,0,50.782,198.348Zm10.823,4.359a4.378,4.378,0,1,1,3.078-1.282A4.194,4.194,0,0,1,61.605,202.707Zm0-6.565a2.189,2.189,0,1,0,1.564.641A2.131,2.131,0,0,0,61.605,196.142Z" transform="translate(-48.5 -188.5)" fill="#8a8d8e"/>
                            </svg>
                        </div>
                    </div>
                    <div className="login-options">
                        <div id="use-mail-button">استخدم رقم الجوال</div>
                        <div id="forgot-paswword-button">نسيت كلمة المرور؟</div>
                    </div>
                    <input className="login-submit-btn" id="login-submit-btn" type="submit" value={"تسجيل الدخول"}/>
                    <div id="notification"></div>
                </form>
            </div>
        </>
    )
}

export default Login;