import React, { useEffect, useState } from "react";
import Order from "./Order";
import { postData,url } from "./imporatnt_functions";
import { Link } from "react-router-dom";
import OrderWindow from "./OrderWindow";

const orderTypeMap = {
    "total":"",
    "preparing":"2",
    "ready":"3",
    "waiting":"1",
    "delivered":"4"
}

const Stock = (props)=>{

    const perPage = 10;

    let [orders,setOrders] = useState([]);
    let [clientName,setClientName] = useState();
    let [orderNumber,setOrderNumber] = useState();
    let [type,setType] = useState(orderTypeMap[props.type]);
    let [pageNum,setPageNum] = useState(1)
    let [numberOfOrders,setNumberOfOrders] = useState({});
    let [searchLength,setSearchLength] = useState(0);
    // let [order,setOrder] = useState("");

    function pagesSubmitHandler(e){
        e.preventDefault();
        const form = document.getElementById("orders-required");
        // console.log(e.target.id)
        if(e.target.id === "perPagesSubmit"){
            if(document.getElementById("pageNum").value !== ""){
                document.getElementById("page").value = +document.getElementById("pageNum").value;
            }
        }else if(e.target.id === "next"){
            document.getElementById("page").value = +document.getElementById("page").value+1;
        }else if(e.target.id === "previous"){
            if(document.getElementById("page").value > 1)
                document.getElementById("page").value  = +document.getElementById("page").value-1;
        }else if(e.target.id === "pageSubmit"){
            if(document.getElementById("pageNum").value !== "")
                document.getElementById("page").value = document.getElementById("pageNum").value;
        }else if(e.target.id === "pageNum"){
            document.getElementById("page").value = e.target.value;
        }
        sessionStorage.setItem("pageNumber",document.getElementById("page").value);
        document.getElementById("pageNum").value = document.getElementById("page").value;
        let data = new FormData(form);
        const bg = document.getElementById("bg");
        const loadingDiv = document.getElementById("loading");
        bg.style.display = "block";
        loadingDiv.classList.toggle("loader")
        loadingDiv.style.display = "block";
        postData(`${url}admin/getMyOrders`,data,localStorage.getItem("userToken")).then(response=>{  
            // console.log("f")
            // setPaginationLimits(response.data.pagination.total,response.data.pagination.perPage);   
            setOrders(response.data.orders)
            bg.style.display = "none";
            loadingDiv.style.display = "none";
            loadingDiv.classList.toggle("loader")
        }).catch(error=>{
            bg.style.display = "none";
            loadingDiv.style.display = "none";
            loadingDiv.classList.toggle("loader")
            return error
        })
    }

    useEffect(()=>{
        setTimeout(()=>{
            const loginAsk = document.getElementById("ask-to-login");
            if(localStorage.getItem("login") === "false"){
                loginAsk.style.display = "block";
                document.getElementById("outer-loader").style.display = "none";
            }else{
                loginAsk.style.display = "none";
                document.getElementById("outer-loader").style.display = "block";

            }
        },100)
        },[])

    useEffect(()=>{
        if(localStorage.getItem("login") === "false") return;
        sessionStorage.removeItem("pageNumber");
        setTimeout(()=>{
            document.getElementById("search-orders-input").value = "";
        },10)
        const ordersRequired = document.getElementById("orders-required");
        setType(orderTypeMap[props.type]);
        setPageNum(1)
        // document.getElementById("page").value = "1";
        let data = new FormData();
        data.set("status",orderTypeMap[props.type]);
        data.set("page",1);
        data.set("perPage",perPage);
        // console.log(Array.from(data))
        // console.log(type)
        const bg = document.getElementById("bg");
        const loadingDiv = document.getElementById("loading");
        bg.style.display = "block";
        loadingDiv.classList.toggle("loader");
        loadingDiv.style.display = "block";
        postData(`${url}admin/getMyOrders`,data,localStorage.getItem("userToken")).then(response=>{
            // console.log("f");
            if(Object.keys(numberOfOrders).length === 0){
                setNumberOfOrders(response.data.total_numbers)
                // console.log(response.data.total_numbers)
                props.setNumberOfOrders(response.data.total_numbers)
            }

            setOrders(response.data.orders)
            if(response.data.orders.length === numberOfOrders[props.type]) document.getElementById("outer-loader").style.display = "none";
            
            bg.style.display = "none";
            loadingDiv.style.display = "none";
            loadingDiv.classList.toggle("loader");
        }).catch(error=>{
            bg.style.display = "none";
            loadingDiv.style.display = "none";
            loadingDiv.classList.toggle("loader");
            return error
        })
        
    },[props.type])

    useEffect(()=>{
        setPageNum(1);
        if(localStorage.getItem("login") === "false") return;
        if(props.searchFor === ""){
            setSearchLength(0);
            window.location.reload()
            return
        } 
        if(props.searchFor === "`") return
        const searchFor = props.searchFor;
        document.getElementById("outer-loader").style.display = "none";
        setTimeout(()=>{
            const ordersRequired = document.getElementById("orders-required");
            const bg = document.getElementById("loading-stock");
            bg.style.display = "block";
            let data = new FormData();
            data.set("status",orderTypeMap[props.type]);
            data.set("page",1);
            data.set("perPage",perPage);
            data.set("search_text",searchFor);
            bg.style.position = "absolute";
            bg.style.height = `700px`
            postData(`${url}admin/getMyOrders`,data,localStorage.getItem("userToken")).then(response=>{
                setOrders(response.data.orders)
                setSearchLength(response.data.pagination.total);
                bg.style.display = "none";
            }).catch(error=>{
                bg.style.display = "none";
                return error
            })
        },5)
    },[props.searchFor])

    const showOrder = (order)=>{
        props.setOrder(order);
    }

    useEffect(()=>{
        if(orders.length === 0){
            if(localStorage.getItem("login") === "false"){
                document.getElementById("not-found").style.display = "none";
                return
            }
            document.getElementById("not-found").style.display = "block";
        }else document.getElementById("not-found").style.display = "none";
    },[orders])

    const setPaginationLimits = (total,perPage,currentPage = 1) =>{

        const pageSelector = document.getElementById("pageNum");
        const n = pageSelector.childNodes.length+1;
        const numberOfPages = Math.ceil(total/perPage);
        while(true){
            if(pageSelector.childNodes.length > numberOfPages){
                pageSelector.removeChild(pageSelector.lastChild);
            }else{
                break
            }
        }
        for(let i = n;i<=numberOfPages;i++){
            const newOption = new Option(i,i);
            pageSelector.add(newOption)
        }

        setTimeout(()=>{
            const previous = document.getElementById("previous")
            const next = document.getElementById("next")
            if(document.getElementById("page").value === "1"){
                previous.disabled = true;
            }else{
                previous.disabled = false;
            }
            if(document.getElementById("page").value === Math.ceil(total/perPage).toString()){
                next.disabled = true;
            }else next.disabled = false;
        },10)
    }

    const scrolling = (e)=>{
        let data = new FormData();
        const loading = document.getElementById("outer-loader");
        if(orders.length === numberOfOrders[props.type]){
            loading.style.display = "none";
            return
        }
        if(e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) < 1){
            if(document.getElementById("search-orders-input").value !== ""){
                if(orders.length === searchLength){
                    loading.style.display = "none";
                    return
                } 
                data.set("search_text",document.getElementById("search-orders-input").value)
            }
            data.set("perPage",perPage)
            data.set("status",type)
            data.set("page",pageNum+1)
            setPageNum(num => ++num)
            
            loading.style.display = "block";
            postData(`${url}admin/getMyOrders`,data,localStorage.getItem("userToken")).then(response=>{
                if(response.data.orders.length === 0){
                    loading.style.display = "none";
                    return
                } 
                setOrders((orders)=>{
                    return [...orders,...response.data.orders]
                })
            }).catch(error=>{
                return error
            })

        }
    }


    return (
        <>
            <div className="stock" id="stock">
                <div className="stock-header">
                    {/* <div className="hide-in-phone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.067" height="17.067" viewBox="0 0 17.067 17.067">
                            <g id="Rectangle_5875" data-name="Rectangle 5875" fill="#fff" stroke="#707070" stroke-width="1">
                                <rect width="17.067" height="17.067" rx="5" stroke="none"/>
                                <rect x="0.5" y="0.5" width="16.067" height="16.067" rx="4.5" fill="none"/>
                            </g>
                        </svg>
                    </div> */}
                    <div>رقم الطلب</div>
                    <div className="hide-in-phone">اسم العميل</div>
                    <div>تاريخ الطلب</div>
                    <div className="hide-in-phone">تاريخ التعديل</div>
                    <div className="hide-in-phone">معد الطلب</div>
                    <div>المستودع</div>
                    <div>حالة الطلب</div>
                    {/* <div>الكمية</div> */}
                    <div className="hide-in-phone">الاجراء</div>
                </div>
                <div className="loading-stock" id="loading-stock"> 
                    <div className="stock-loading-text" id="stock-loading-text"> 
                    </div>
                    {/* <div className="ask-to-login" id="ask-to-login" style={{display:"none"}}>
                        قم <button className="ask-to-login-button" onClick={e=>props.showLoginForm(1)}>بتسجيل الدخول</button> لمتابعة طلباتك
                    </div> */}
                </div>
                <div id="orders-container" className="orders-container" onScroll={scrolling}>
                    <div className="ask-to-login" id="ask-to-login" style={{display:"none"}}>
                        قم <button className="ask-to-login-button" onClick={e=>props.showLoginForm(e)}>بتسجيل الدخول</button> لمتابعة طلباتك
                    </div>
                    <div id="not-found" className="not-found">لم يتم إيجاد طلب</div>
                {
                    orders.map((order)=>{
                        return(
                            <Order key={order.id} order={order} showOrder={showOrder}/>
                            )
                        })
                    }
                    <div id="outer-loader" className="outder-loader">
                        <div className="orders-loader"></div>
                    </div>
                </div>
                {/* <div className="orders-loading"> */}
                    {/* <div className="orders-loader"></div> */}
                {/* </div> */}
                {/* <form id="orders-required" className="pages">
                    <div className="hidden-options" style={{display:"none"}}>
                        <input type="text" name="search_text" value={props.searchFor}/>
                        <input type="text" name="order_number" value={orderNumber}/>
                        <input type="text" name="status" value={type}/>
                        <input type="number" id="page" name="page" defaultValue={"1"}/>
                    </div>
                    <div className="ordersPerPage">
                        <label htmlFor="perPage">عدد الطلبات في الصفحة :</label>
                        <input type="number" id="perPage" name="perPage" defaultValue="15" min={1}/>
                        <input type="submit" id="perPagesSubmit" value={"تم"} onClick={pagesSubmitHandler}/>
                    </div>
                    <div className="pagination">
                        <div>
                            <label htmlFor="page" className="page-label">الصفحة&nbsp;:</label>
                            <input type="submit" id="next" value={"التالي"} onClick={pagesSubmitHandler}/>
                            <input type="number" id="page" name="page" defaultValue={"1"}/>
                            <input type="submit" id="previous" value={"السابق"} onClick={pagesSubmitHandler}/>
                        </div>
                        <p>أو</p>
                        <div> 
                            <input type="number" id="pageNum" min={1} placeholder={"أدخل رقم الصفحة"}/>
                            <input type="submit" id="pageSubmit" value={"إذهب"} onClick={pagesSubmitHandler}/>
                            <select name="pageNum" id="pageNum" onChange={pagesSubmitHandler}>
                                <option value="1" selected>1</option>
                            </select>
                        </div>
                    </div>
                </form> */}
            </div>

            
        </>
    )
}

export default Stock;