import React, { useEffect } from "react";
import Header2 from "./Header2";
import Header from "./Header";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postData,getWarehouses,url } from "./imporatnt_functions";

const ModifyOrder = (props)=>{

    let [image,setImage] = useState('');
    let [orderDate,setOrderDate] = useState();
    let [dueDate,setDueDate] = useState();

    const navigate = useNavigate();

    const location = useLocation();
    const order = location.state;
    // console.log(order)
    // console.log(order.order_date)

    function addPicture(e){
        // console.log(e.target.files[0])
        if(e.target.files[0]){
            let image = URL.createObjectURL(e.target.files[0]);
            document.getElementById("warehouse_image").src = image;
            // console.log(image)
            setImage(e.target.files[0]);
        }
    }

    const modify = (e)=>{
        e.preventDefault();
        const form = document.getElementById("new-order");
        let data = new FormData(form)
        const dataArray = Array.from(data);
        let f=0;
        for(let field of dataArray){
            if(field[1] === ""){
                document.getElementById(field[0]).style.borderColor = "red";

                let counter = 0;
                const shaking = setInterval(()=>{
                    if(counter === 1){
                        clearInterval(shaking)
                    }
                    counter ++;
                    document.getElementById(field[0]).style.position = "relative"
                    // document.getElementById(field[0]).style.left = "0px";
                    document.getElementById(field[0]).style.transform = "translateX(5px)";
                    setTimeout(()=>{
                        document.getElementById(field[0]).style.transform = "translateX(-5px)";
                    },50);
                    setTimeout(()=>{
                        document.getElementById(field[0]).style.transform = "translateX(0px)";
                    },70);
                },100)

                f=1;
            }else 
                document.getElementById(field[0]).style.borderColor = "#1A7279";
        }
        if(f) return
        const bg = document.getElementById("bg");
        const loadingDiv = document.getElementById("loading");
        bg.style.display = "block";
        loadingDiv.classList.toggle("loader");
        loadingDiv.style.display = "block";
        postData(`${url}admin/updateAdminOrders`,data,localStorage.getItem("userToken")).then(response=>{
            loadingDiv.classList.toggle("loader");
            loadingDiv.classList.toggle("loading");
            if(response.status === 500){
                throw new Error("server error");
            }
            if(response.status === "false"){
                loadingDiv.innerText = "حدث خطأ.";
            }
            else 
                loadingDiv.innerText = "تم";
            setTimeout(()=>{
                bg.style.display = "none";
                loadingDiv.style.display = "none";
                loadingDiv.classList.toggle("loading");
                loadingDiv.innerText = "";
                if(response.status !== "false") navigate(-1); 
            },1000);
        }).catch(error=>{
            loadingDiv.innerText = "حدث خطأ.";
            setTimeout(()=>{
                bg.style.display = "none";
                loadingDiv.style.display = "none";
                loadingDiv.classList.toggle("loading");
                loadingDiv.innerText = "";
            },1000);
            return error
        })
    }

    const setWarehouses = async ()=>{
        const warehouses = document.getElementById("warehouse_id")
        getWarehouses(localStorage.getItem("userToken")).then(response=>{
            // console.log(response)
            response.warehouses.forEach(warehouse=>{
                const option = new Option(warehouse.name,warehouse.id)
                if(order.warehouse_id === warehouse.id){
                    option.selected = true;
                }
                warehouses.add(option);
            })
            // console.log(warehouses);
        }).catch(err=>err)
    }

    useEffect(()=>{
        const dueDate = new Date(order.due_date);
        const year = dueDate.getFullYear();
        const month = (dueDate.getMonth() + 1).toString().padStart(2, "0");
        const day = dueDate.getDate().toString().padStart(2, "0");
        const orderDate = new Date(order.order_date);
        const orderYear = orderDate.getFullYear();
        const orderMonth = (orderDate.getMonth() + 1).toString().padStart(2, "0");
        const orderDay = orderDate.getDate().toString().padStart(2, "0");
        setOrderDate(`${orderYear}-${orderMonth}-${orderDay}`)
        setDueDate(`${year}-${month}-${day}`)
        setWarehouses()
    },[order])


    return(
        <>
        <div className="new-order-page">
            <Header/>
            <div className="create-new-order-main-container modify-container">
            <div className="create-new-order-header">
                    <header>تعديل طلب جديد</header>
                    <button className="return-to-main" id="return-create-order" onClick={e=>navigate(-1)}>
                        الرجوع
                        &#8678;
                    </button>
                </div>
                <form id="new-order" className="order-options">
                    <div className="modify-form">
                        <div className="right-side-container">
                            <input style={{display:"none"}} type="text" name="id" id="id" value={order.id}/>
                            <label htmlFor="warehouse_id">المستودع</label>
                            <select name="warehouse_id" id="warehouse_id">
                            </select>
                            <label htmlFor="order_number">رقم الطلب</label>
                            <input type="number" name="order_number" id="order_number" value={order.order_number}/>
                            <label htmlFor="client_name">اسم العميل</label>
                            <input type="text" name="client_name" id="client_name" defaultValue={order.client_name}/>
                            <label htmlFor="order_date">تاريخ الطلب</label>
                            <input type="date" name="order_date" id="order_date" defaultValue={orderDate}/>
                            <label htmlFor="due_date">موعد التسليم</label>
                            <input type="date" name="due_date" id="due_date" defaultValue={dueDate}/>
                            <label htmlFor="order_status">حالة الطلب</label>
                            <select type="date" name="status" id="status" defaultValue={order.status}>
                                <option value="2">جاري التجهيز</option>
                                <option value="3">جاهز</option>
                                <option value="1">في الانتظار</option>
                                <option value="4">تم التسليم</option>
                            </select>
                        </div>
                        <div className="image-side-container">
                            <img id="warehouse_image" src="./image.jpg" width={"300px"} height={"300px"} className="warehouse-image" alt="صورة المستودع"/>
                            <label htmlFor="image">
                                <svg style={{color:"#fff"}} fill="#fff" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h360v80H200v560h560v-360h80v360q0 33-23.5 56.5T760-120H200Zm480-480v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80ZM240-280h480L570-480 450-320l-90-120-120 160Zm-40-480v560-560Z"/></svg>
                                إضافة صورة
                            </label>
                            <input type="file" name="image" accept="image/png,image/jpg,image/jpeg" id="image" onChange={addPicture}/>
                        </div>
                    </div>
                    <button id="newOrderSubmitButton" onClick={modify}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.03" height="21.461" viewBox="0 0 20.03 21.461">
                            <g id="save" transform="translate(-12.16 -6.08)">
                                <path id="Path_32481" data-name="Path 32481" d="M31.876,10.835l-4.44-4.44a1.066,1.066,0,0,0-.759-.314H13.233A1.074,1.074,0,0,0,12.16,7.153V26.468a1.074,1.074,0,0,0,1.073,1.073H31.117a1.074,1.074,0,0,0,1.073-1.073V11.593a1.066,1.066,0,0,0-.314-.759ZM25.037,7.511V11.8H18.6V7.511Zm5.723,18.6H13.591V7.511h3.577v5.723h9.3V7.511h.061l4.231,4.231Z" fill="#fff"/>
                                <path id="Path_32482" data-name="Path 32482" d="M64.913,85.12a4.113,4.113,0,1,0,4.113,4.113A4.113,4.113,0,0,0,64.913,85.12Zm0,6.8A2.683,2.683,0,1,1,67.6,89.233,2.683,2.683,0,0,1,64.913,91.916Z" transform="translate(-42.917 -69.74)" fill="#fff"/>
                            </g>
                        </svg>
                        حفظ المعلومات
                    </button>
                </form>
            </div>
        </div>
        </>       
    )
}

export default ModifyOrder;